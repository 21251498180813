/* Behavior - behaviors/slide.js */

$(function () {
    var $imgBg = $('.img-bg');

    if(!$imgBg.length) return

    // Slideshow
    $imgBg.each(function() {
        var item = $(this);
        var image = $(item).data('image');

        $(item).css({
            'backgroundImage': "url("+image+")"
        });
    });
});