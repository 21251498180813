/* Behavior - behaviors/webfont-load.js */

$(function() {
    
    // Load Fonts
    WebFont.load({
        google: {
            families: ['Abel:400','Teko:300,400,500&display=swap']
        }
    });
});